<template>
  <v-card class="glow" :loading="loading">
    <v-img height="200" src="@/assets/card-bg-only-salesperson.jpg">
      <v-card-title class="white--text">
        <h3 class="pa-2">{{ title }}</h3>
      </v-card-title>
      <p class="pl-10 pr-10 white--text">
        {{ instruction }}
      </p>
    </v-img>

    <v-card-text class="pl-10 pr-10">
      <ApiError :errors="errors"></ApiError>

      <div v-if="loadingEnroll" class="text-center">
        <v-progress-circular indeterminate></v-progress-circular>
        <h3 class="mt-5">Processing...</h3>
        <p>Please do not close this window or refresh the page.</p>

        <h4 class="mb-4">
          We are currently processing your enrollment and creating your reloable card ...
        </h4>
      </div>

      <div v-else-if="step === 'PROFILE'">
        <v-form ref="joinForm" v-model="valid.step1" class="mb-5">
          <v-container>
            <v-row>
              <v-col cols="12">
                <RelatedDealersTable
                  ref="relatedDealersTable"
                  :value.sync="organizations"
                  :loading="loading"
                  :hasError.sync="hasError"
                  :participantTypeKeyForOrganization.sync="participantTypeKeyForOrganization"
                  :enrolling="!$kawasaki.isSalesperson()"
                  @participantTypeChanged="updateParticipantTypeKey"
                />
              </v-col>
            </v-row>

            <span v-if="!fromRegister">
              <h3 class="pt-5 pb-5">Please confirm your Address and Phone Number</h3>
              <p class="pb-5">
                Your Kawasaki prepaid card will be mailed to your address. Please verify that we have your address and
                phone number correct.
              </p>
              <v-row>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="py-0">
                  <v-text-field
                    dense
                    name="address1"
                    :label="$i18n.translate('Address Line') + ' ' + 1"
                    v-model="form.address.address1"
                    :rules="rules.address1"
                    :error-messages="$error.getValidationError(errors, 'address1')"
                    @input="$error.clearValidationError(errors, 'address1')"
                    class="required"
                    outlined
                  />
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="py-0">
                  <v-text-field
                    id="address2"
                    name="address2"
                    :label="$i18n.translate('Address Line') + ' ' + 2"
                    v-model="form.address.address2"
                    :rules="rules.address2"
                    :error-messages="$error.getValidationError(errors, 'address2')"
                    @input="$error.clearValidationError(errors, 'address2')"
                    dense
                    outlined
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" class="py-0">
                  <v-text-field
                    id="city"
                    name="city"
                    :label="$i18n.translate('City')"
                    v-model="form.address.city"
                    :rules="rules.city"
                    :error-messages="$error.getValidationError(errors, 'city')"
                    @input="$error.clearValidationError(errors, 'city')"
                    class="required"
                    dense
                    outlined
                  />
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" class="py-0">
                  <RegionField
                    id="region"
                    name="region"
                    :label="$i18n.translate('Region')"
                    :country="form.address.country"
                    v-model="form.address.region"
                    :rules="rules.region"
                    :error-messages="$error.getValidationError(errors, 'region')"
                    @input="$error.clearValidationError(errors, 'region')"
                    class="required"
                    dense
                    outlined
                  />
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" class="py-0">
                  <PostalCodeField
                    id="postalCode"
                    name="postalCode"
                    :label="$i18n.translate('Postal Code')"
                    :country="form.address.country"
                    v-model="form.address.postalCode"
                    :rules="rules.postalCode"
                    :error-messages="$error.getValidationError(errors, 'postalCode')"
                    @input="$error.clearValidationError(errors, 'postalCode')"
                    class="required"
                    dense
                    outlined
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" class="py-0">
                  <PhoneNumberField
                    id="phoneNumber1"
                    name="phoneNumber1"
                    :label="$i18n.translate('Phone Number')"
                    v-model="form.phoneNumber1"
                    :error-messages="$error.getValidationError(errors, 'phoneNumber1')"
                    @input="$error.clearValidationError(errors, 'phoneNumber1')"
                    :rules="rules.phoneNumber"
                    :country="form.address.country"
                    class="required"
                    dense
                    outlined
                  ></PhoneNumberField>
                </v-col>
              </v-row>
            </span>

            <span
              v-if="participantTypeKeyForOrganization == '300' && selectedParticipant && !selectedParticipant.user.tin"
            >
              <h3 class="pt-5 pb-5">Please enter your SSN</h3>
              <p class="pb-5">
                Your SSN is used to issue a Kawasaki prepaid card that will be used for your earnings. If your earnings
                are high enough within a one year period, we may be required to report a 1099 form.
              </p>

              <v-row>
                <v-col cols="12" xs="12" sm="12" md="8" lg="8" xl="4" class="py-0">
                  <TinField
                    tooltip="We collect SSN for card issuance and 1099 reporting"
                    dense
                    outlined
                    v-model="form.transientTin"
                    :country="selectedParticipant.address.country"
                    class="required"
                    :rules="rules.tin"
                    :isRegistered="true"
                    clearable
                  />
                </v-col>
              </v-row>
            </span>
            <v-row>
              <v-col class="text-right">
                <v-btn :loading="loading" color="primary" :disabled="!validForm1" @click="handleNext()">
                  Next <v-icon small right>mdi-arrow-right</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </div>

      <div v-else-if="step === 'AGREEMENT'">
        <v-form ref="joinForm" v-model="valid.step2" class="mb-5">
          <SalespersonSiteAgreement :loading="loading" @previous="changeStep('PROFILE')" @next="handleAccepted" />
        </v-form>
      </div>

      <div v-else-if="step === 'COMPLETE'" class="text-center">
        <v-icon x-large>mdi-flag-checkered</v-icon>

        <h3 class="mb-4">Your Registration has been Submitted!</h3>

        <template v-if="participant?.status?.name === 'ENR'">
          <h4 class="mb-4">
            Thank you for joining the Sales Rewards program. We're excited to have you onboard!
          </h4>
          <p>
            If you have any questions or need further assistance, please feel free to contact our support team.
          </p>
          <v-btn class="my-8" :loading="loading" color="primary" @click="onFinished">
            Continue
          </v-btn>
        </template>

        <template v-else-if="participant?.status?.name === 'PRE'">
          <p>Thank you for joining the Sales Rewards program.</p>
          <p>Your request has been forwarded and is currently pending approval.</p>
          <p>You will receive an email with additional details once your account has been reviewed.</p>

          <v-btn class="my-8" :loading="loading" color="secondary" @click="backToLogin">
            Login
          </v-btn>
        </template>

        <template v-else>
          <p>We are unable to determine your registration status at the moment.</p>
          <v-btn class="my-8" :loading="loading" color="secondary" @click="backToLogin">
            Return to Login
          </v-btn>
        </template>
      </div>

      <div v-else-if="step === 'CARD_ERROR'" class="text-center">
        <v-icon x-large color="red">mdi-alert-circle</v-icon>

        <h3 class="mb-4">Account Created Successfully</h3>

        <h4 class="mb-4">
          However, we encountered an error while issuing your card.
        </h4>

        <p>
          Please contact Kawasaki support for assistance. They will help resolve the issue and ensure your card is
          issued promptly.
        </p>

        <v-btn class="my-8" color="primary" @click="onFinished">
          Continue
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import ApiError from "@/gapp-components/components/display/ApiError.vue";
import TinField from "@/gapp-components/components/fields/TinField.vue";
import ValidationService from "@/gapp-components/services/validation.service";
import RelatedDealersTable from "@/views/common/table/RelatedDealersTable.vue";
import SalespersonSiteAgreement from "@/views/salesperson/SalespersonSiteAgreement.vue";
import moment from "moment-timezone";
import { mapGetters } from "vuex";
import PhoneNumberField from "../../gapp-components/components/fields/PhoneNumberField.vue";
import PostalCodeField from "../../gapp-components/components/fields/PostalCodeField.vue";
import RegionField from "../../gapp-components/components/fields/RegionField.vue";

export default {
  components: {
    ApiError,
    SalespersonSiteAgreement,
    RelatedDealersTable,
    TinField,
    RegionField,
    PostalCodeField,
    PhoneNumberField
  },
  name: "SalespersonJoin",
  metaInfo: {
    title: "Join the Sales Rewards Program"
  },
  data: () => ({
    step: "PROFILE",
    valid: {
      step1: false,
      step2: false
    },
    form: {
      address: {},
      transientTin: ""
    },
    organizations: {
      existing: [],
      delete: []
    },
    participantTypeKey: "300",
    participantTypeKeyForOrganization: null,
    hasError: false,
    complete: false,
    loading: false,
    loadingEnroll: false,
    errors: {},
    participant: {},
    fromRegister: false
  }),
  computed: {
    ...mapGetters(["selectedParticipant"]),
    validAll() {
      return this.validForm1;
    },
    validForm1() {
      return !this.hasError && this.organizations.existing?.length > 0 && this.validSSN;
    },
    validSSN() {
      return this.valid.step1;
    },
    title() {
      if (this.loadingEnroll) {
        return "Kawasaki Sales Rewards - Enrolling ...";
      } else if (this.step === "PROFILE") {
        return "Kawasaki Sales Rewards - Sales Profile";
      } else if (this.step === "AGREEMENT") {
        return "Kawasaki Sales Rewards - Sales Agreement";
      } else if (this.step === "COMPLETE") {
        return "Kawasaki Sales Rewards";
      } else {
        return "";
      }
    },
    instruction() {
      if (this.loadingEnroll) {
        return "We are working to enroll your account ...";
      } else if (this.step === "PROFILE") {
        return "The Kawasaki Sales Rewards Program keeps Kawasaki dealership personnel up to date on the latest Kawasaki product. Dealership sales people may qualify to earn incentives by selling eligible models through the Kawasaki Sales Rewards Prepaid Card. K-Elite On-Line Training is available and courses are model specific, but qualification to earn awards is unique and within each of the product type categories.";
      } else if (this.step === "AGREEMENT") {
        return "Please review and accept the following Kawasaki Sales Agreement.";
      } else if (this.step === "COMPLETE") {
        return "Your submission to join the Sales Rewards program has been successfully submitted!";
      } else {
        return "";
      }
    },
    rules() {
      return {
        address1: [
          ValidationService.generateNotWhitespacesRule("Address 1"),
          ...ValidationService.generateAddressValidationRules("Address 1")
        ],
        address2: [
          ValidationService.generateNotWhitespacesRule("Address 2"),
          ...ValidationService.generateAddressValidationRules("Address 2", 200, false)
        ],
        city: [
          ValidationService.generateNotWhitespacesRule("City"),
          ...ValidationService.generateTextValidationRules("City", 100, true)
        ],
        region: ValidationService.generateDropdownValidationRules("Region"),
        postalCode: ValidationService.generateTextValidationRules("Postal Code"),
        phoneNumber: ValidationService.generateTextValidationRules("Phone Number"),
        tin: [...ValidationService.generateTextValidationRules("Social Security Number")]
      };
    }
  },
  mounted() {
    this.form.address = this.selectedParticipant.address;
    this.form.phoneNumber1 = this.selectedParticipant.phoneNumber1;
    this.fromRegister = this.$route.query.register == "1";

    this.changeStep("PROFILE");
  },

  methods: {
    async handleNext() {
      this.loading = true;
      try {
        await this.$kawasaki.updateSalespersonDealers(this.organizations, this.participantTypeKey);
      } catch (error) {
        console.error(error);
        this.errors = this.$api.getErrorsFromResponse(error);
      } finally {
        this.changeStep("AGREEMENT");
        this.$refs.relatedDealersTable.resetOrganizations();
        this.loading = false;
      }
    },
    handleAccepted() {
      this.submitAndJoin();
    },

    changeStep(s) {
      this.step = s;
      window.scrollTo(0, 0);
    },

    async submitAndJoin() {
      try {
        this.errors = {};
        this.loading = true;
        this.loadingEnroll = true;

        // If the selected participant type key for the selected organization is different than current participant type key, then enroll the participant
        // Otherwise, update the participant information
        if (this.participantTypeKeyForOrganization != this.selectedParticipant.participantType.participantTypeKey) {
          await this.enrollParticipant();
        } else {
          await this.updateParticipant();
        }

        try {
          await this.$kawasaki.createReloadableCard("SALESPERSON_PAYOUTS");
          this.changeStep("COMPLETE");
        } catch (cardError) {
          this.changeStep("CARD_ERROR");
          console.error("Error issuing card:", cardError);
        }
      } catch (error) {
        console.log(error);
        this.errors = this.$api.getErrorsFromResponse(error);
        window.scrollTo(0, 0);
      } finally {
        this.loading = false;
        this.loadingEnroll = false;
      }
    },

    async updateParticipant() {
      let postForm = {};

      postForm.address = {
        address1: this.form.address.address1,
        address2: this.form.address.address2,
        city: this.form.address.city,
        postalCode: this.form.address.postalCode,
        region: this.form.address.region
      };
      postForm.phoneNumber1 = this.form.phoneNumber1;

      postForm.customFieldValue1 = moment().format("YYYY-MM-DD");
      postForm.customFieldValue2 = "true";
      postForm.termsAndConditionsAccepted = true;

      // Force approval if participant type is not a sales person
      if (this.participantTypeKey != "300") {
        postForm.status = { name: "PRE" };
      }

      const { data } = await this.$api.patch("/api/participants/" + this.selectedParticipant.id, postForm);
      if (this.form.transientTin) {
        await this.$api.post("/api/users/setTin", { tin: this.form.transientTin });
      }

      if (data) {
        this.participant = data;
        const emailTemplateForm = this.prepareEmailTemplateForm();
        try {
          await this.$api.post("/api/emailTemplates/sendToParticipant", emailTemplateForm);
        } catch (emailError) {
          console.error("Error sending salesperson join email");
        }
      }
    },

    enrollParticipant() {
      let postForm = {
        participant: {
          status: { name: this.participantTypeKey != "300" ? "PRE" : "ENR" },
          participantType: { participantTypeKey: this.participantTypeKey },
          email1: this.selectedParticipant.email1,
          user: {
            username: this.selectedParticipant.user.username,
            email: this.selectedParticipant.user.email
          },
          address: this.selectedParticipant.address,
          customFieldValue1: moment().format("YYYY-MM-DD"),
          customFieldValue2: "true",
          termsAndConditionsAccepted: true,
          customFieldValue7: this.form?.customFieldValue7,
          customFieldValue10: this.form?.customFieldValue10,
          customFieldValue11: this.form?.customFieldValue11?.join("|"),
          customFieldValue12: this.form?.customFieldValue12,
          customFieldValue13: this.form?.customFieldValue13,
          customFieldValue14: this.form?.customFieldValue14,
          customFieldValue15: this.form?.customFieldValue15
        },
        emailTemplateParticipantForm: { emailTemplateKey: "SALES_REWARDS_JOIN" }
      };

      if (!this.fromRegister) {
        postForm.participant.address = {
          address1: this.form.address.address1,
          address2: this.form.address.address2,
          city: this.form.address.city,
          postalCode: this.form.address.postalCode,
          region: this.form.address.region,
          country: this.selectedParticipant.address.country
        };
        postForm.participant.phoneNumber1 = this.form.phoneNumber1;
      }

      return this.$api.post("/api/participants/enroll", postForm).then(({ data }) => {
        this.participant = data;
        return this.$auth.storeAvailableParticipants().then(() => {
          return this.$auth.storeSelectParticipant(data).then(() => {
            // If User already exists, a TIN is not updated during enroll, so updated it here
            if (this.form.transientTin) {
              return this.$api.post("/api/users/setTin", { tin: this.form.transientTin });
            } else {
              return Promise.resolve();
            }
          });
        });
      });
    },

    prepareEmailTemplateForm() {
      const emailTemplateForm = { participant: { id: this.participant.id } };

      if (this.participant?.status?.name === "ENR") {
        emailTemplateForm.emailTemplateKey = "SALES_REWARDS_JOIN";
      } else if (this.participant?.status?.name === "PRE") {
        emailTemplateForm.emailTemplateKey = "SALES_REWARDS_PENDING";
      }

      return emailTemplateForm;
    },

    updateParticipantTypeKey(participantTypeKey) {
      this.participantTypeKey = participantTypeKey;
      this.participantTypeKeyForOrganization = participantTypeKey;
    },

    async onFinished() {
      this.loading = true;
      try {
        const { data } = await this.$api.get("/api/participants/bySelectedParticipant");
        await this.$auth.storeSelectParticipant(data);
        await this.$auth.storeAvailableParticipants();
        this.$router.push({ name: "dashboard" });
      } catch (error) {
        console.error("Error during participant update:", error);
      } finally {
        this.loading = false;
      }
    },

    backToLogin() {
      this.$router.push({ name: "login" });
    }
  }
};
</script>
