<template>
  <v-container>
    <v-row>
      <v-col>
        <ContentView contentKey="SALES_REWARDS_SITE_AGREEMENT" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn :loading="loading" @click="goBack"> <v-icon small left>mdi-arrow-left</v-icon> Back </v-btn>
      </v-col>
      <v-col class="text-right">
        <v-btn
          :rules="[v => !!v || 'You must accept the agreements']"
          :loading="loading"
          color="primary"
          @click="goNext"
          class="ml-2"
        >
          I Agree, Next <v-icon small right>mdi-arrow-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import ContentView from "../../gapp-components/components/common/content/ContentView.vue";
export default {
  components: { ContentView },
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    goBack() {
      this.$emit("previous");
    },
    goNext() {
      this.$emit("next");
    }
  },
  mounted() {
    this.$content.refreshContent();
  }
};
</script>
